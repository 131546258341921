import React from 'react';

import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

ReactGA.initialize('UA-101646049-1');

export default class Blog extends React.Component{

  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render(){
    return(
      <section>

        <Helmet>
          <title>Grimmsdorf&reg; the Board Game - Game Rules</title>
          <meta name="description" content="Grimmsdorf&reg; is a 2-4 player coöperative game of lore, exploration, and survival set in a dark fairytale world. In this world of struggle and ignorance, wearied villagers are never sure where reality ends and fantasy begins. Fairies, famines, wolves, and witches seem to lurk around every bend. Real history and oral mythologies are blended together in one world of mystery, fear, and adventure." />
        </Helmet>

        <h2>Game Rules</h2>
        <p>You can download the Grimmsdorf rulebook as a PDF <a href="/grimmsdorf-rulebook.pdf" target="_blank" rel="noopener noreferrer">here</a>.</p>

      </section>
    );
  }
}
