import React from 'react';

import { Link } from 'react-router-dom';
import DataBlogPosts from './data-blog-posts.json';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

ReactGA.initialize('UA-101646049-1');

export default class Blog extends React.Component{

  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render(){
    const requested_blog_post = this.props.blogPost;
    if(requested_blog_post == null){
      return(
        <section>

          <Helmet>
            <title>Grimmsdorf&reg; the Board Game - Development Blog</title>
            <meta name="description" content="Grimmsdorf&reg; is a 2-4 player coöperative game of lore, exploration, and survival set in a dark fairytale world. Read about our challenges and successes in the game design and development blog." />
          </Helmet>

          <h2>Development Blog</h2>
          <p><em>Looking for posts about our newer games? Check out the <a href="https://calamitybaygames.com/blog" target="_blank" rel="noopener noreferrer">Calamity Bay Games blog</a> to read about our latest efforts.</em></p>
            {
              DataBlogPosts.map(function(blogPost, index){
                return(
                  <div key={blogPost.route}>
                    <h3><Link to={'/blog/'+blogPost.route}>{blogPost.title}</Link></h3>
                    <p><em>{blogPost.author}, {blogPost.date}</em></p>
                    <p>{blogPost.teaser_text}</p>
                    <p><Link to={'/blog/'+blogPost.route}>Read more...</Link></p>
                  </div>
                );
              })
            }
        </section>
      );
    } else {
      return(
        <section>
          {
            DataBlogPosts.filter(blogPost => blogPost.route === requested_blog_post).map(function(blogPost, index){
              let blogText = '';
              blogPost.text.map(function(textLine, index){
                return blogText += textLine;
              });
              return(
                <section key={blogPost.title}>

                  <Helmet>
                    <title>Grimmsdorf&reg; the Board Game - Development Blog - {blogPost.title}</title>
                    <meta name="description" content={"Grimmsdorf&reg; is a 2-4 player coöperative game of lore, exploration, and survival set in a dark fairytale world. Read about our challenges and successes in this blog post, " + blogPost.title + "."} />
                  </Helmet>

                  <h2>{blogPost.title}</h2>
                  <p><em>{blogPost.author}, {blogPost.date}</em></p>
                  <div dangerouslySetInnerHTML = {
                    { __html: blogText }
                  } />
                  <p><em>Like what you see here? <Link to={`/join-mailing-list`}>Join our mailing list</Link> and stay up-to-date with our game-making adventures.</em></p>
                  <p><Link to={`/blog`}>&larr; Back to blog posts</Link></p>
                </section>
              );
            })
          }
        </section>
      );
    }
  }
}
