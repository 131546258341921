import React from 'react';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import Slide1 from './images/grimmsdorf-slide-1.jpg';
import Slide2 from './images/grimmsdorf-slide-2.jpg';
import Slide3 from './images/grimmsdorf-slide-3.jpg';
import Slide4 from './images/grimmsdorf-slide-4.jpg';
import Slide5 from './images/grimmsdorf-slide-5.jpg';
import Slide6 from './images/grimmsdorf-slide-6.jpg';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

ReactGA.initialize('UA-101646049-1');

export default class About extends React.Component{

  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render(){
    return(

      <section className="about-page">

        <Helmet>
          <title>Grimmsdorf&reg; the Board Game - About</title>
          <meta name="description" content="Grimmsdorf&reg; is a 2-4 player coöperative town-building game of lore, exploration, and survival set in a dark fairytale world. In this world of struggle and ignorance, wearied villagers are never sure where reality ends and fantasy begins. Fairies, famines, wolves, and witches seem to lurk around every bend. Real history and oral mythologies are blended together in one world of mystery, fear, and adventure." />
        </Helmet>

        <section>
          <CarouselProvider
            naturalSlideWidth={800}
            naturalSlideHeight={450}
            totalSlides={6}
            isPlaying={true}
            interval={4000}
          >
            <Slider>
              <Slide index={0}><img className="top_banner" src={Slide1} width="100%" alt="Grimmsdorf is a fully coöperative, storytelling/ exploration/ town-building game set in an isolated fairytale forest." title="Grimmsdorf is a fully coöperative, storytelling/ exploration/ town-building game set in an isolated fairytale forest." /></Slide>
              <Slide index={1}><img className="top_banner" src={Slide2} width="100%" alt="Grimmsdorf is a fully coöperative, storytelling/ exploration/ town-building game set in an isolated fairytale forest." title="Grimmsdorf is a fully coöperative, storytelling/ exploration/ town-building game set in an isolated fairytale forest." /></Slide>
              <Slide index={2}><img className="top_banner" src={Slide3} width="100%" alt="Grimmsdorf is a fully coöperative, storytelling/ exploration/ town-building game set in an isolated fairytale forest." title="Grimmsdorf is a fully coöperative, storytelling/ exploration/ town-building game set in an isolated fairytale forest." /></Slide>
              <Slide index={3}><img className="top_banner" src={Slide4} width="100%" alt="Grimmsdorf is a fully coöperative, storytelling/ exploration/ town-building game set in an isolated fairytale forest." title="Grimmsdorf is a fully coöperative, storytelling/ exploration/ town-building game set in an isolated fairytale forest." /></Slide>
              <Slide index={3}><img className="top_banner" src={Slide5} width="100%" alt="Grimmsdorf is a fully coöperative, storytelling/ exploration/ town-building game set in an isolated fairytale forest." title="Grimmsdorf is a fully coöperative, storytelling/ exploration/ town-building game set in an isolated fairytale forest." /></Slide>
              <Slide index={3}><img className="top_banner" src={Slide6} width="100%" alt="Grimmsdorf is a fully coöperative, storytelling/ exploration/ town-building game set in an isolated fairytale forest." title="Grimmsdorf is a fully coöperative, storytelling/ exploration/ town-building game set in an isolated fairytale forest." /></Slide>
            </Slider>
          </CarouselProvider>
        </section>

        <h2>About the Game</h2>
        <p>Grimmsdorf is a 2-4 player coöperative town-building game of lore, exploration, and survival set in a dark fairytale forest sometime in Europe's distant past. In this world of struggle and ignorance, wearied villagers are never sure where reality ends and fantasy begins. Fairies, famines, wolves, and witches seem to lurk around every bend. Real history and oral mythologies are blended together in one world of mystery, fear, and adventure.</p>
        <p>Players have to work together to avoid starvation, overcome magickal curses, build up their town, and explore the vast wilderness beyond their small world if they want a chance to survive. The themes and mechanics are tightly woven together, to create a rich and compelling experience for players.</p>
        <p>If they don't meet a trickster dwarf or a pack of hungry wolves first, 40 unique fairytale characters - both familiar and obscure - can strike out on their adventures based on their original fairytales. They'll have to balance their thirst for glory with the need to work the land, and construct helpful buildings such as apothecaries, scriptoriums, and hunting lodges. But if they succeed, their stories will live on and they can become the stuff of legend.</p>

        <p>No two games are alike:</p>
        <ul>
          <li>70+ unique Event cards</li>
          <li>40 unique characters</li>
          <li>21 unique Lore Quests</li>
          <li>7 unique Hex cards</li>
          <li>randomized terrain generation</li>
          <li>Epilogue based on the outcomes of your game</li>
        </ul>

        <h2>Gameplay &amp; Mechanics</h2>
        <ul>
          <li>2-4 players | 90-180 minutes | ages 14+</li>
          <li>Fully coöperative; mix of autonomous actions and team strategizing</li>
          <li>Tile-laying: players explore the vast wilderness outside the village of Grimmsdorf</li>
          <li>Story-telling: players take their characters through their fairy-tale quests</li>
          <li>Resource management: The town must harvest enough food to feed its people to avoid starvation</li>
          <li>Engine building: Players work together to build strategies and maximize resource production</li>
          <li>Strategize collectively to overcome chance: in this coöperative game, the antagonist is the harsh world</li>
        </ul>

        <h2>Game Assets</h2>
        <p>In case additional copies are desired, or a digital version would be handy, here are several game assets available in PDF form:</p>
        <ul>
          <li>You can download the Grimmsdorf rulebook as a PDF <a href="/grimmsdorf-rulebook.pdf" target="_blank" rel="noopener noreferrer">here</a>.</li>
          <li>You can download the Lore Quest booklet, as individual sheets, for phone viewing: <a href="/grimmsdorf-lore-quest-sheets-digital.pdf" target="_blank" rel="noopener noreferrer">here (black background)</a>.</li>
          <li>You can download the Lore Quest booklet, as individual sheets, for printing: <a href="/grimmsdorf-lore-quest-sheets-printable.pdf" target="_blank" rel="noopener noreferrer">here (white background)</a>.</li>
        </ul>

        <h2>The Team</h2>
        <p>Grimmsdorf is a creation of husband-and-wife team Elle Kelsheimer and Mike Caputo - collectively known as <a href="https://calamitybaygames.com" target="_blank" rel="noopener noreferrer">Calamity Bay Games</a> - headquartered in Kingston, NY.</p>
      </section>
    );
  }
}
