import React from 'react';

import {
  Switch,
  Link
} from 'react-router-dom';

import { Route } from "react-router";
import ReactGA from 'react-ga';

import wordmark from './images/wordmark.png';
import bggLogo from './images/bgg-logo-bw.png';
import box_flourish from './images/box_flourish.png';
import cta_flourish from './images/cta_flourish.png';
import './stylesheets/styles.css';

import TheStories from './TheStories';
import About from './About';
import Blog from './Blog';
import JoinMailingList from './JoinMailingList';
import Contact from './Contact';
import ThankYou from './ThankYou';
import GameRules from './GameRules';
import DataStories from './data-stories.json';
import DataBlogPosts from './data-blog-posts.json';

ReactGA.initialize('UA-101646049-1');

export default class App extends React.Component {
  constructor(){
    super();
    this.state = {
      page: 'about',
      menu_active: false
    }
  }

  _handleNav(page){
    this.setState({
      page: page,
      menu_active: false
    });
  }

  _toggleMenu(){
    this.setState({
      menu_active: !this.state.menu_active
    });
  }

  render() {
    return (
      <div className="App">
        <div className="container">
          <header>
            <Link to={`/`} onClick={() => this._handleNav('about')}>
              <img src={wordmark} className="App-logo" alt="Grimmsdorf&reg; - a coöperative town-building game of lore, exploration, and survival" title="Grimmsdorf&reg; - a coöperative town-building game of lore, exploration, and survival" />
            </Link>
            <h1 className="hide">Grimmsdorf&reg;</h1>
            <span>a coöperative town-building game of lore, exploration, and survival</span>
          </header>
          <nav>
            <img src={cta_flourish} className="flourish top-left" alt="" title="" />
            <p className="cta-text">
              <a href="https://thegamecrafter.com/games/grimmsdorf" target="_blank" rel="noopener noreferrer"><br />Buy now</a>
            </p>
            <div className="links">
              <div className={this.state.menu_active ? 'links-wrapper active' : 'links-wrapper'}>
                <span className="menu-button" onClick={() => this._toggleMenu()}><span className="icon-menu" title="Menu"></span> Menu</span>
                <Link to={`/`} onClick={() => this._handleNav('about')} className={this.state.page === 'about' ? 'active' : ''}>About</Link>
                <a href="https://thegamecrafter.com/games/grimmsdorf" target="_blank" rel="noopener noreferrer">Buy Now</a>
                <Link to={`/blog`} onClick={() => this._handleNav('blog')} className={this.state.page === 'blog' ? 'active' : ''}>Development Blog</Link>
                <Link to={`/the-stories`} onClick={() => this._handleNav('thestories')} className={this.state.page === 'thestories' ? 'active' : ''}>The Stories</Link>
              </div>
            </div>
            <img src={box_flourish} className="flourish top-right" alt="" title="" />
          </nav>
          <div className="main-container">
            <div className="left-border"></div>

            <main>
              <Switch>
                <Route path='/contact' component={Contact}/>
                <Route path='/join-mailing-list' component={JoinMailingList}/>
                <Route path='/thank-you' component={ThankYou}/>
                <Route exact path='/blog' component={Blog}/>
                <Route exact path='/the-stories' component={TheStories}/>
                <Route exact path='/rules' component={GameRules} />
                {
                  DataBlogPosts.map(function(blogPost, index){
                    return(
                      <Route key={blogPost.route} exact path={'/blog/'+blogPost.route} render={()=><Blog blogPost={blogPost.route} />} />
                    );
                  })
                }
                {
                  DataStories.map(function(story, index){
                    return(
                      <Route key={story.route} exact path={'/the-stories/'+story.route} render={()=><TheStories story={story.route} />} />
                    );
                  })
                }
                <Route path='/' component={About}/>
              </Switch>
            </main>

            <div className="right-border"></div>
          </div>
          <footer>
            <img src={box_flourish} className="flourish bottom-left" alt="" title="" />
            <p>Grimmsdorf&reg; is &copy; <a href="https://calamitybaygames.com" target="_blank" rel="noopener noreferrer">Calamity Bay Games LLC</a>&trade;</p>
            <p className="social-links">
              <a href="https://discord.gg/mhhzZCk9JC" target="_blank" rel="noopener noreferrer"><span className="icon-discord" title="Chat with us on Discord"></span></a> &middot;&nbsp;
              <a href="https://boardgamegeek.com/boardgame/254434/grimmsdorf" target="_blank" rel="noopener noreferrer"><img src={bggLogo} height="25" alt="Become a fan on BoardGameGeek" title="Become a fan on BoardGameGeek" /></a> &middot;&nbsp;
              <a href="https://instagram.com/calamitybaygames/" target="_blank" rel="noopener noreferrer"><span className="icon-instagram" title="Follow us on Instagram"></span></a> &middot;&nbsp;
              <Link to={`/contact`} onClick={() => this._handleNav('contact')}><span className="icon-envelop" title="Contact Us"></span></Link>
            </p>
            <img src={box_flourish} className="flourish bottom-right" alt="" title="" />
          </footer>
        </div>
      </div>
    );
  }
}
