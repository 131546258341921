import React from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

ReactGA.initialize('UA-101646049-1');

export default class JoinMailingList extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      emailValid: true,
      hasMessage: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(e){
    const email = this.state.email;
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = regex.test(String(email).toLowerCase());
    this.setState({
      emailValid: valid,
      hasMessage: !!this.state.message
    });

    if(this.state.email === '' || !valid || this.state.message === ''){
      e.preventDefault();
      return false;
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render(){
    return(
      <section>

        <Helmet>
          <title>Grimmsdorf&reg; the Board Game - Contact</title>
          <meta name="description" content="Grimmsdorf&reg; is a 2-4 player coöperative game of lore, exploration, and survival set in a dark fairytale world. In this world of struggle and ignorance, wearied villagers are never sure where reality ends and fantasy begins. Fairies, famines, wolves, and witches seem to lurk around every bend. Real history and oral mythologies are blended together in one world of mystery, fear, and adventure." />
        </Helmet>

        <h2>Contact</h2>
        <p>Thoughts, comments, questions about Grimmsdorf? Do you have any cool house rules you've created or want to share other ideas? Feel free to drop us a line.</p>
        <form action="https://formspree.io/hello@calamitybaygames.com" method="POST" onSubmit={this.submitForm}>
          <label>Name</label>
          <input type="text" name="name" value={this.state.name} onChange={this.handleChange} />
          <label>Email *</label>
          <input type="email" name="email" value={this.state.email} onChange={this.handleChange} />
          <p className={this.state.emailValid ? 'none' : 'block form-error'}>Please check that the email address is correct.</p>
          <label>Message *</label>
          <textarea rows="4" name="message" value={this.state.message} onChange={this.handleChange}></textarea>
          <p className={this.state.hasMessage ? 'none' : 'block form-error'}>Please write a message.</p>
          <input type="hidden" name="_next" value="/thank-you" />
          <input type="hidden" name="_subject" value="Grimmsdorf contact form" />
          <button type="submit" title="On submission, you will be asked to click a Captcha button. After that one click, you will be brought back to the Grimmsdorf website.">Submit</button>
        </form>
      </section>
    );
  }
}
