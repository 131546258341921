import React from 'react';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-101646049-1');

export default class JoinMailingList extends React.Component{

  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render(){
    return(
      <section>
        <h2>Thank You</h2>
        <p>We've received your form submission. Thanks for being a part of Grimmsdorf's success!</p>
      </section>
    );
  }
}
