import React from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

ReactGA.initialize('UA-101646049-1');

export default class JoinMailingList extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      emailValid: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(e){
    const email = this.state.email;
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = regex.test(String(email).toLowerCase());
    this.setState({
      emailValid: valid
    });
    if(this.state.email === '' || !valid){
      e.preventDefault();
      return false;
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render(){
    return(
      <section>

        <Helmet>
          <title>Grimmsdorf&reg; the Board Game - Join our Mailing List</title>
          <meta name="description" content="Grimmsdorf&reg; is a 2-4 player coöperative game of lore, exploration, and survival set in a dark fairytale world. Grimmsdorf was just the first game developed by Calamity Bay Games. Join our mailing list to follow our other game development efforts!" />
        </Helmet>

        <h2>Join our Mailing List</h2>
        <p>Grimmsdorf was just the first game developed by Calamity Bay Games. Join our mailing list to follow our other game development efforts!</p>
        <label>Name</label>
        <form action="https://formspree.io/hello@calamitybaygames.com" method="POST" onSubmit={this.submitForm}>
          <input type="text" name="name" value={this.state.name} onChange={this.handleChange} />
          <label>Email *</label>
          <input type="email" name="email" svalue={this.state.email} onChange={this.handleChange} />
          <p className={this.state.emailValid ? 'none' : 'block form-error'}>Please check that the email address is correct.</p>
          <input type="hidden" name="_next" value="/thank-you" />
          <input type="hidden" name="_subject" value="Someone wants to join the mailing list" />
          <button type="submit" title="On submission, you will be asked to click a Captcha button. After that one click, you will be brought back to the Grimmsdorf website.">Submit</button>
        </form>
      </section>
    );
  }
}
