import React from 'react';

import { Link } from 'react-router-dom';
import DataStories from './data-stories.json';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

ReactGA.initialize('UA-101646049-1');

export default class TheStories extends React.Component{

  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render(){
    const requested_story = this.props.story;
    if(requested_story == null){
      return(
        <section>

          <Helmet>
            <title>Grimmsdorf&reg; the Board Game - The Stories</title>
            <meta name="description" content="Grimmsdorf&reg; is a 2-4 player coöperative game of lore, exploration, and survival set in a dark fairytale world. Read the full texts - both familiar and obscure - of the tales featured in Grimmsdorf." />
          </Helmet>

          <h2>The Stories</h2>
          <p>This is a selection of the tales featured in Grimmsdorf.</p>
          <ul>
            {
              DataStories.map(function(story, index){
                return(
                  <li key={story.route}><Link to={'/the-stories/'+story.route}>{story.title}</Link></li>
                );
              })
            }
          </ul>

          <h2>Grimmsdorf's Approach to the Stories</h2>
          <p>Many of the stories that have entered popular culture have been stripped of their more grotesque or otherwise unpalatable elements. This process happened over a long period of time; indeed it began during the lifetime of the Brothers Grimm. Their first edition contained the undoctored versions, straight from the oral sources. But as the stories became more renowned and popular through publishing, the Brothers Grimm were pressured by the Church and other elements of society to make the stories more morally punctuated and to be friendlier for families and children.</p>
          <p>It is our aim to use the original stories whenever possible, to stay true to the original underpinnings of the oral tales. But because the themes need to mesh with game mechanics, Grimmsdorf is not a strict or literal representation of the original tales.</p>
          <p>We at Grimmsdorf have also had to make other adjustments and concessions while creating the game. In order to adapt the tales to the Lore Quests featured in the game, various changes were made. Many tales reference particular times or places, or functioning castles and lordships. An important part of Grimmsdorf is isolation and mystery, so we made adjustments as needed to make the tales fit as best as possible. In several instances, we have taken pieces of a fairy tale and incorporated it into an Event card. We aim to make these modifications as respectfully as possible while incorporating them into a well-designed, functioning game.</p>

        </section>
      );
    } else {
      return(
        <section>
          {
            DataStories.filter(story => story.route === requested_story).map(function(story, index){
              let storyText = '';
              story.text.map(function(textLine, index){
                return storyText += textLine;
              });
              return(
                <section key={story.title}>
                  <Helmet>
                    <title>Grimmsdorf&reg; the Board Game - {story.title}</title>
                    <meta name="description" content={"Grimmsdorf&reg; is a 2-4 player coöperative game of lore, exploration, and survival set in a dark fairytale world. Read the full text of " + story.title + ", one of the many tales featured in Grimmsdorf."} />
                  </Helmet>
                  <h2>{story.title}</h2>
                  <div dangerouslySetInnerHTML = {
                    { __html: storyText }
                  } />
                  <p><Link to={`/the-stories`}>&larr; Back to the Stories</Link></p>
                </section>
              );
            })
          }
        </section>
      );
    }
  }
}